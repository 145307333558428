<script setup lang="ts">
import { mdiMagnify } from "@mdi/js"
import type { VTextField } from "vuetify/components"

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface Props
  extends /* @vue-ignore */ Omit<VTextField["$props"], "modelValue"> {}

defineProps<Props>()

const query = defineModel<string | null>("query", { required: true })
</script>

<template>
  <v-text-field
    v-bind="$props"
    v-model="query"
    clearable
    class="search-input"
    variant="solo"
    :prepend-inner-icon="mdiMagnify"
    hide-details
    placeholder="Etf, Stock, ticker, ISIN, name"
  />
</template>

<style lang="scss">
.search-input {
  border-radius: 12px;
  .v-field {
    border-radius: 12px;
    background: rgb(48, 48, 48) !important;
  }
}

.search-input {
  .v-field.v-field--focused {
    background: rgb(56, 56, 56) !important;
  }
  input::placeholder {
    // font-style: italic;
    font-size: 14px;
  }
}
</style>
